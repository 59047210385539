<template>
  <div class="plays">
    <h1>Plays</h1>
  </div>
</template>

<script>
export default {
  name: "Plays",
};
</script>
