<template>
  <div class="holdings">
    <h1>Holdings</h1>
  </div>
</template>

<script>
export default {
  name: "Holdings",
};
</script>
